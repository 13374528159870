// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
/** Please use https://ionicframework.com/docs/theming/color-generator **/
:root {
  // Color generator: https://ionicframework.com/docs/theming/color-generator
  --ion-color-primary: #023a46;
  --ion-color-primary-rgb: 2, 58, 70;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #02333e;
  --ion-color-primary-tint: #1b4e59;

  --ion-color-secondary: #4c8793;
  --ion-color-secondary-rgb: 26, 79, 89;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #17464e;
  --ion-color-secondary-tint: #31616a;

  --ion-color-tertiary: #809ca3;
  --ion-color-tertiary-rgb: 128, 156, 163;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #71898f;
  --ion-color-tertiary-tint: #c7d5d8;

  --ion-color-success: #3e9c59;
  --ion-color-success-rgb: 62, 156, 89;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #37894e;
  --ion-color-success-tint: #51a66a;

  --ion-color-warning: #e89d21;
  --ion-color-warning-rgb: 232, 157, 33;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #cc8a1d;
  --ion-color-warning-tint: #eaa737;

  --ion-color-danger: #d36262;
  --ion-color-danger-rgb: 211, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #ba5656;
  --ion-color-danger-tint: #d77272;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #f9f9f9;
  --ion-color-medium-rgb: 249, 249, 249;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #dbdbdb;
  --ion-color-medium-tint: #fafafa;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-info: #809ca3;
  --ion-color-info-rgb: 56, 128, 255;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #3171e0;
  --ion-color-info-tint: #4c8dff;

  --ion-color-grey: #999999;
  --ion-color-grey-rgb: 153, 153, 153;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #878787;
  --ion-color-grey-tint: #a3a3a3;

  --ion-grid-padding: 8px;
  --ion-grid-column-padding: 8px;
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

[theme="dark"] {
  --ion-color-dark-tint: #1d1d1f;
  --ion-color-dark-area: #252527;
  --ion-color-dark-line: #48474a;
  --ion-background-color: var(--ion-color-dark-tint);
  --ion-color: var(--ion-color-dark-tint);
  --ion-item-color: var(--ion-color-primary-contrast) !important;
  --ion-color-medium: var(--ion-color-dark-tint) !important;
  --ion-color-medium-rgb: var(--ion-color-dark-tint) !important;
  --ion-color-medium-contrast: #fbfbfb;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: var(--ion-color-dark-tint) !important;
  --ion-color-medium-tint: var(--ion-color-dark-tint) !important;
  --ion-color-secondary: #17464e;
  --ion-color-secondary-rgb: 26, 79, 89;
  --ion-color-secondary-shade: #17464e;
  --ion-color-secondary-tint: #17464e;
}
